import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/Layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="Home" activeHeaderLink="Home" mdxType="Layout">
      <h1>{`404`}</h1>
      <p>{`Not Found. The resource requested could not be found on this server!`}</p>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      